import axios  from "axios";
import React, { Suspense, useContext, useEffect, useMemo } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { getDecodedToken, getToken, refreshToken, removeToken, setToken } from "../services/User.service";
import { AuthoriseContext, axiosApiInstance } from "../App";
import { errorToast } from "../utils/Toast";
import { lazy } from 'react';
const About = lazy(() => import('../components/About'));
const Account = lazy(() => import('../components/Account/Account'));
const Login = lazy(() => import('../components/Auth/Login'));
const Register = lazy(() => import('../components/Auth/Register'));
const ResetPassword = lazy(() => import('../components/Auth/ResetPassword'));
const BlogDetail = lazy(() => import('../components/BlogDetail'));
const Blogs = lazy(() => import('../components/Blogs'));
const FAQ = lazy(() => import('../components/FAQ'));
const FeedBack = lazy(() => import('../components/FeedBack'));
const Gallery = lazy(() => import('../components/Gallery'));
const Index = lazy(() => import('../components/Index'));
const Footer = lazy(() => import('../components/Layout/Footer'));
const Header = lazy(() => import('../components/Layout/Header'));
const PrivacyPolicy = lazy(() => import('../components/PrivacyPolicy'));
const CartPage = lazy(() => import('../components/Shop/CartPage'));
const OrderComplete = lazy(() => import('../components/Shop/OrderComplete'));
const Procedure = lazy(() => import('../components/Shop/Procedure'));
const ProductDetail = lazy(() => import('../components/Shop/ProductDetail'));
const Products = lazy(() => import('../components/Shop/Products'));
const RaiseDispute = lazy(() => import('../components/Shop/RaiseDispute'));
const ReturnRefund = lazy(() => import('../components/Shop/ReturnRefund'));
const SubCategory = lazy(() => import('../components/Shop/SubCategory'));
const TrackOrder = lazy(() => import('../components/Shop/TrackOrder'));
const ViewOrder = lazy(() => import('../components/Shop/ViewOrder'));
const SingleReview = lazy(() => import('../components/SingleReview'));
const TermsCondition = lazy(() => import('../components/TermsCondition'));
const Wishlist = lazy(() => import('../components/Wishlist'));
const ForgetPasword = lazy(() => import('../components/Auth/ForgetPassword'));
const Refund = lazy(() => import('../components/Refund'));
const Shipping = lazy(() => import('../components/Shipping'));

export default function RootRouter() {
  const [isAuthorized, setIsAuthorized] = useContext(AuthoriseContext);
  const navigate = useNavigate();

  const location = useLocation();
  const CheckIsAuthorised = async () => {
    let token = getToken();
    if (token) {
      setIsAuthorized(true);
    } else {
      setIsAuthorized(false);
    }
  };
  const logoutUser = () => {
    removeToken();
    setIsAuthorized(false);
  };

  useEffect(() => {
    CheckIsAuthorised();
    // console.log(location, "location");
    // if (isAuthorized && `${location.pathname}` == "/") navigate(-1);
  }, [isAuthorized]);

  useMemo(() => {
    let token = getToken();
    // console.log(token, "token");
    axiosApiInstance.interceptors.request.use(
      async (config) => {
        // console.log(token)
        if (token) {
          config.headers["authorization"] = "Bearer " + token;
        }
        // config.headers['Content-Type'] = 'application/json';
        return config;
      },
      (error) => {
        console.log(error);
        Promise.reject(error);
      }
    );
    axiosApiInstance.interceptors.response.use(
      (res) => {
        // Add configurations here
        return res;
      },
      async (err) => {
        // console.log("INterceptor error");
          let {config,response} = err;
          if(response && response.status == 401){
            if(!token){
              // errorToast("Please Login ")
              // navigate("/login")
            } 
              let decode = getDecodedToken();

                let refreshTokenResponse = await refreshToken(decode);

                if(refreshTokenResponse.data && refreshTokenResponse.data.success){
                    let refreshToken = refreshTokenResponse.data.token;
                    await  setToken(refreshToken);
                    await  new Promise(resolve => {
                      config.headers["authorization"] = "Bearer " + refreshToken;
                      // console.log(config,"configconfigconfig")
                        resolve(axios(config))
                      })
                }

              // console.log(refreshTokenResponse,"responseerror")
          }
        // logoutUser()

        return Promise.reject(err);
      }
    );
  }, [isAuthorized]);

  useMemo(() => {
    let token = getToken();
    // console.log(token, "token")
    axiosApiInstance.interceptors.request.use(
      async (config) => {
        // console.log(token)
        if (token) {
          config.headers['authorization'] = 'Bearer ' + token;
        }
        // config.headers['Content-Type'] = 'application/json';
        return config;
      },
      error => {
        console.log(error)
        Promise.reject(error)
      });
    axiosApiInstance.interceptors.response.use(
      (res) => {
        // Add configurations here
        return res;
      },
      async (err) => {
        // console.log("INterceptor error")

        // logoutUser()

        return Promise.reject(err);
      }
    );
  }, [])




  return (
    <>
      <Header auth={isAuthorized} />
      <Suspense fallback={<>Loading...</>}>

      <Routes>
        {
          isAuthorized &&
          <>
            <Route path="/Wishlist" exact element={<Wishlist />} />
            <Route path="/Account" exact element={<Account />} />
            <Route path="/ViewOrder" exact element={<ViewOrder />} />
            <Route path="/TrackOrder" exact element={<TrackOrder />} />
            <Route path="/ReturnRefund" exact element={<ReturnRefund />} />
            <Route path="/RaiseDispute" exact element={<RaiseDispute />} />
          </>
        }

        <Route path="/" exact element={<Index />} />
        <Route path="/About-Us" exact element={<About />} />
        <Route path="/Cart" exact element={<CartPage />} />
        <Route path="/FAQ" exact element={<FAQ />} />
        <Route path="/Terms-and-Condition" exact element={<TermsCondition />} />
        <Route path="/Return-and-Refund-Policy" exact element={<Refund />} />
        <Route path="/Shipping-and-Payment-Policy" exact element={<Shipping />} />
        <Route path="/Privacy-Policy" exact element={<PrivacyPolicy />} />
        <Route path="/Blogs" exact element={<Blogs />} />
        <Route path="/Blog-Detail/:slug" exact element={<BlogDetail />} />
        <Route path="/Login" exact element={<Login />} />
        <Route path="/Register" exact element={<Register />} />
        <Route path="/ResetPassword/:id" exact element={<ResetPassword />} />
        <Route path="/ForgetPasword" exact element={<ForgetPasword />} />
        <Route path="/SubCategory/:id" exact element={<SubCategory />} />
        <Route path="/Gallery" exact element={<Gallery />} />
        <Route path="/FeedBack" exact element={<FeedBack />} />
        <Route path="/Procedure" exact element={<Procedure />} />
        <Route path="/Products/:id" exact element={<Products />} />
        <Route path="/Product-Detail/:id" exact element={<ProductDetail />} />
        <Route path="/single-review/:id" exact element={<SingleReview />} />
        <Route path="/OrderComplete/:id" exact element={<OrderComplete />} />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      </Suspense>
 
      <Footer />
    </>
  )
}
