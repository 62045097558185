import axios from "axios";



import React, { createContext, useEffect, useState } from "react";
import { useReducer } from "react";
import {Helmet} from "react-helmet";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { injectStyle } from "react-toastify/dist/inject-style";
import 'react-toastify/dist/ReactToastify.css';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "./assets/scss/main.css";
import ScrollToTop from "./components/ScrollToTop";
import "./components/Utility/Style";
import RootRouter from "./Router/RootRouter";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { getSeoBySlugApi } from "./services/Attribute.service";
import { generateImageUrl } from "./services/url.service";
export let AuthoriseContext = createContext();
export let CartContext = createContext();
export let CartCountContext = createContext();
export let FilterContext = createContext();
export let ProductFilterContext = createContext();
export let ShippingCountryContext = createContext();
export let  CategoryContext = createContext();
export let  SystemContext = createContext();

export const axiosApiInstance = axios.create();
function App() {

  const [isAuthorized, setIsAuthorized] = useState(false);
  const [showCartSideBar, setShowCartSideBar] = useState(false);
  const [contextCounry, setcontextCounry] = useState("");
  const [carts, setCarts] = useState(0);
  const [categoryArr, setCategoryArr] = useState([]);
  const [systemSettingObj, setSystemSettingObj] = useState([]);
  const [seoObj, setSeoObj] = useState(null);
  const [products, setProducts] = useState({
    products: [],
    categoryId: "",
  });
  const [filter, setFilter] = useState({
    sortBy: 'date_desc',
    categoryArr: [],
    priceRangeArr: {
      start: "",
      end: ""
    }
  });

  injectStyle();
  const getSeoByUrl = async (url) => {

    try {
      let{data:res} = await getSeoBySlugApi(url);
      if(res.data){
        setSeoObj(res.data)
      }
    } catch (error) {
        console.error(error)      
    }

  }
  
  useEffect(() => {
  let currenctUrl = window.location.href;
  getSeoByUrl(currenctUrl)
  }, [])


  return (
    <AuthoriseContext.Provider value={[isAuthorized, setIsAuthorized]}>
      <CategoryContext.Provider value={[categoryArr, setCategoryArr]}>
      <CartContext.Provider value={[showCartSideBar, setShowCartSideBar]}>
      <SystemContext.Provider value={[systemSettingObj, setSystemSettingObj]}>
        <CartCountContext.Provider value={[carts, setCarts]}>
          <ProductFilterContext.Provider value={[products, setProducts]}>
            <ShippingCountryContext.Provider value={[contextCounry, setcontextCounry]}>
              <FilterContext.Provider
                value={[filter, setFilter]}
              >
                <PayPalScriptProvider
                  options={{

                    ///////test credentails
                    // "client-id": "Abx-wfh6f2UkiPNujqDyH-YWpf0pvnpqsCcG6GPeEBCip5ckySla-jWZsqXD7uaR7WI4_2NXCnE4im2Z",
                    ///////live credentails
                    "client-id": "AfPRbINqQzRL2n7_gDI5m-4HFwGj8ubKcziAvrBxGXklaNGzv4muvGEJkyV9BC_y6xjyzr8oWarAqrDS",
                    components: "buttons",
                    // currency: currency
                  }}
                >

                  <BrowserRouter>
                    <ScrollToTop />
                    <ToastContainer position="bottom-right" />
                    {
                            seoObj && seoObj?._id ?(
                              <Helmet>
                              <title>{seoObj.title}</title>
                              <meta
                                name="description"
                                content={`${seoObj.description}`}
                              />
                              <link rel="canonical" href={`${window.location.href}`} />
                              <meta
                                name="keywords"
                                content={`${seoObj.keywords}`}
                              />{
                                seoObj.imageUrl && (
                                <meta property="og:image" content={`${generateImageUrl(seoObj.imageUrl)}`} />
                                )
                              }
                              <meta property="og:title"    content={`${seoObj.title}`} />
                              <meta property="og:site_name" content="Aahilya"/>
                              <meta property="og:url" content={`${window.location.href}`}/>
                              <meta property="og:description"   content={`${seoObj.description}`} />
                              <meta property="og:type" content="website"/>
                              <meta name="geo.placename" content={`${seoObj.geoLocation}`} />
                              <meta id="Language" name="language" content={`${seoObj.language}`} />
                              <meta id="Country" name="Country" content={`${seoObj.country}`} />
                              <meta id="Distribution" name="distribution" content={`${seoObj.distribution}`} />
                            </Helmet>
                            ) : (
                              <></>
                            )
                  }
                   
                    <RootRouter />
                  </BrowserRouter>
                </PayPalScriptProvider>
              </FilterContext.Provider>
            </ShippingCountryContext.Provider>
          </ProductFilterContext.Provider>
        </CartCountContext.Provider>
      </SystemContext.Provider>
      </CartContext.Provider>
      </CategoryContext.Provider>
    </AuthoriseContext.Provider >
  );
}

export default App;
